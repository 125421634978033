import axios from "axios";
import { UserInfo } from "../contexts/UserContext";
import { CollectionResponseDTO } from "../types/ApiTypes";
import { UserSearchResultType } from "../types/SearchTypes";
import { Role, User } from "../types/UserTypes";

const API_URL = `${process.env.SERVICES_URL}/api/users`;

export const UserApi = {
  getUser: (userId: string): Promise<User> =>
    axios.get(`${API_URL}/${userId}`).then((response: { data: User }) => response.data),
  getUserInfo: (): Promise<UserInfo> => axios.get(API_URL).then((response: { data: UserInfo }) => response.data),
  getUsers: (): Promise<UserSearchResultType[]> =>
    axios
      .get(API_URL + "/search")
      .then((response: { data: CollectionResponseDTO<UserSearchResultType> }) => response.data.collection),
  resetPermissions: (user: User): Promise<User> =>
    axios.put(API_URL + "/permissions", user).then((response: { data: User }) => response.data),
  saveUsers: (users: User[]): Promise<User[]> =>
    axios.put(API_URL, users).then((response: { data: CollectionResponseDTO<User> }) => response.data.collection),
  saveRoles: (roles: Role[]): Promise<Role[]> =>
    axios
      .put(`${API_URL}/roles/update`, roles)
      .then((response: { data: CollectionResponseDTO<Role> }) => response.data.collection),
};
