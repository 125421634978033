import { useEffect } from "react";

export function useExternalScript(src: string) {
  useEffect(() => {
    if (!!src) {
      const head = document.querySelector("head");
      const script = document.createElement("script");
      script.setAttribute("src", src);
      head.appendChild(script);
    }
  }, [src]);
}
