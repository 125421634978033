import "best-common-react/lib/styles/bcr.css";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./assets/fonts/Roboto-Bold.ttf";
import "./assets/fonts/Roboto-Italic.ttf";
import "./assets/fonts/Roboto-Light.ttf";
import "./assets/fonts/Roboto-Medium.ttf";
import "./assets/fonts/Roboto-Regular.ttf";
import "./assets/fonts/RobotoSlab-Bold.ttf";
import "./assets/fonts/RobotoSlab-Regular.ttf";
import WrapperProvider from "./modules/WrapperProvider";

window.digitalData = {
  push(): void {
    return;
  },
};

const blueApp = document.getElementById("blue-app");
const root = createRoot(blueApp);
root.render(
  <WrapperProvider>
    <App />
  </WrapperProvider>,
);
