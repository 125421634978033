import React, { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Alerts from "./components/elements/Alerts";
import { useUser } from "./contexts/UserContext";
import { useExternalScript } from "./hooks/useExternalScript";

const AuthenticatedApp = lazy(() => import("./modules/AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./modules/UnauthenticatedApp"));

const App: React.FC = () => {
  const { loggedIn } = useUser();
  useExternalScript(process.env.ADOBE_ANALYTICS_URL);

  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <Alerts />
      <BrowserRouter>{loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}</BrowserRouter>
    </Suspense>
  );
};

export default App;
