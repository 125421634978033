import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Roles } from "../constants/RolesConstants";
import { useUser } from "./UserContext";

type PermissionsContextType = {
  hasAnyPermission: (permissions: string[]) => boolean;
  hasPermission: (permission: string) => boolean;
  isAdmin: boolean;
  isDirector: boolean;
  isSupervisor: boolean;
  isSupervisorMajor: boolean;
  isSupervisorMinor: boolean;
  isObserver: boolean;
  isObserverMajor: boolean;
  isObserverMinor: boolean;
  isUmpire: boolean;
  isUmpireMajor: boolean;
  isUmpireMinor: boolean;
  isUmpireCallup: boolean;
  isUmpireMinorCampChief: boolean;
  isUmpireSubstitute: boolean;
  isMinorLeagueUmpire: boolean;
};

const PermissionsContext = createContext<PermissionsContextType | undefined>(undefined);

const PermissionsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { userInfo } = useUser();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isDirector, setIsDirector] = useState<boolean>(false);
  const [isSupervisor, setIsSupervisor] = useState<boolean>(false);
  const [isSupervisorMajor, setIsSupervisorMajor] = useState<boolean>(false);
  const [isSupervisorMinor, setIsSupervisorMinor] = useState<boolean>(false);
  const [isObserver, setIsObserver] = useState<boolean>(false);
  const [isObserverMajor, setIsObserverMajor] = useState<boolean>(false);
  const [isObserverMinor, setIsObserverMinor] = useState<boolean>(false);
  const [isUmpire, setIsUmpire] = useState<boolean>(false);
  const [isUmpireMajor, setIsUmpireMajor] = useState<boolean>(false);
  const [isUmpireMinor, setIsUmpireMinor] = useState<boolean>(false);
  const [isUmpireCallup, setIsUmpireCallup] = useState<boolean>(false);
  const [isUmpireMinorCampChief, setIsUmpireMinorCampChief] = useState<boolean>(false);
  const [isUmpireSubstitute, setIsUmpireSubstitute] = useState<boolean>(false);

  const checkForRole = (role: string) => {
    setIsAdmin(role === Roles.ADMIN);
    setIsDirector(role === Roles.DIRECTOR);
    setIsSupervisor(role === Roles.SUPERVISOR_MAJOR || role === Roles.SUPERVISOR_MINOR);
    setIsSupervisorMajor(role === Roles.SUPERVISOR_MAJOR);
    setIsSupervisorMinor(role === Roles.SUPERVISOR_MINOR);
    setIsObserver(role === Roles.OBSERVER_MAJOR || role === Roles.OBSERVER_MINOR);
    setIsObserverMajor(role === Roles.OBSERVER_MAJOR);
    setIsObserverMinor(role === Roles.OBSERVER_MINOR);
    setIsUmpire(
      role === Roles.UMPIRE_MAJOR ||
        role === Roles.UMPIRE_MINOR ||
        role === Roles.UMPIRE_CALLUP ||
        role === Roles.UMPIRE_MINOR_CAMP_CHIEF ||
        role === Roles.UMPIRE_SUBSTITUTE,
    );
    setIsUmpireMajor(role === Roles.UMPIRE_MAJOR);
    setIsUmpireMinor(role === Roles.UMPIRE_MINOR || role === Roles.UMPIRE_MINOR_CAMP_CHIEF);
    setIsUmpireCallup(role === Roles.UMPIRE_CALLUP);
    setIsUmpireMinorCampChief(role === Roles.UMPIRE_MINOR_CAMP_CHIEF);
    setIsUmpireSubstitute(role === Roles.UMPIRE_SUBSTITUTE);
  };

  const hasAnyPermission = (permissions: string[]): boolean => {
    return !!permissions.filter((permission) => hasPermission(permission)).length;
  };

  const hasPermission = (permission: string): boolean => {
    return !!userInfo?.permissions.filter((p) => p.name === permission).length;
  };

  useEffect(() => {
    if (!!userInfo) {
      checkForRole(userInfo.role.name);
    }
  }, [userInfo]);

  const isMinorLeagueUmpire = useMemo(
    () => isUmpireMinor || isUmpireCallup || isUmpireMinorCampChief || isUmpireSubstitute,
    [isUmpireMinor, isUmpireCallup, isUmpireMinorCampChief, isUmpireSubstitute],
  );

  return (
    <PermissionsContext.Provider
      value={{
        hasAnyPermission,
        hasPermission,
        isAdmin,
        isDirector,
        isSupervisor,
        isSupervisorMajor,
        isSupervisorMinor,
        isObserver,
        isObserverMajor,
        isObserverMinor,
        isUmpire,
        isUmpireMajor,
        isUmpireMinor,
        isUmpireCallup,
        isUmpireMinorCampChief,
        isUmpireSubstitute,
        isMinorLeagueUmpire,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

const usePermissions = (): PermissionsContextType => {
  const permissionsContext: PermissionsContextType = useContext<PermissionsContextType>(PermissionsContext);
  if (permissionsContext === undefined) {
    throw new Error(`usePermissions must be used within a PermissionsProvider`);
  }
  return permissionsContext;
};

export { PermissionsContext, PermissionsProvider, usePermissions };
