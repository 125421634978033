export const Roles = {
  ADMIN: "admin",
  DIRECTOR: "director",
  OBSERVER_MAJOR: "observer.major",
  OBSERVER_MINOR: "observer.minor",
  SUPERVISOR_MAJOR: "supervisor.major",
  SUPERVISOR_MINOR: "supervisor.minor",
  UMPIRE_MAJOR: "umpire.major",
  UMPIRE_MINOR: "umpire.minor",
  UMPIRE_CALLUP: "umpire.callup",
  UMPIRE_MINOR_CAMP_CHIEF: "umpire.minor.camp_chief",
  UMPIRE_SUBSTITUTE: "umpire.substitute",
  UNASSIGNED: "unassigned",
};
