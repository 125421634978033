import { AlertConstants, AlertHolder, useAlerts } from "best-common-react";
import React from "react";
import styled from "styled-components";

const AlertWrapper = styled.div`
  > div {
    > div {
      display: table;
      left: 0px;
      right: 0px;
      margin: 0 auto;
      width: fit-content;
      > div {
        margin-right: 10px;
      }
    }
  }
`;

const Alerts: React.FC = () => {
  const { removeAlert, alerts } = useAlerts();

  // TODO: Add error self-closing to BCR and remove this forEach function
  // Force errors to close after 15 seconds
  alerts?.forEach((a) => {
    if (a.type === AlertConstants.TYPES.DANGER && a.selfClosing) {
      a.closeTime = new Date(new Date().getTime() + 15000);
      a.selfClosing = false;
    }
  });

  return (
    <AlertWrapper id="blue-alerts">
      <AlertHolder
        alerts={alerts}
        onRemove={(id: number | string): void => {
          if (id && removeAlert) {
            removeAlert(Number(id));
          }
        }}
      />
    </AlertWrapper>
  );
};

export default Alerts;
