import { OktaAuth } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { UserApi } from "../api/UserApi";
import config from "../config";
import { UserRoleConstants } from "../constants/UserConstants";
import { BaseUser } from "../types/UserTypes";

type UserInfo = BaseUser & {
  accessToken: { accessToken: string };
};

type UserContext = {
  userInfo: UserInfo;
  loggedIn: boolean;
  login: (data: UserInfo) => void;
  logout: () => void;
  permissionError: boolean;
  loginFunction: SetStateAction<void>;
  setPermissionError: Dispatch<SetStateAction<boolean>>;
  setLoginFunction: Dispatch<SetStateAction<void>>;
  getUserId(): number;
  isAdminUser: () => boolean;
};

const UserContext = createContext<UserContext>({
  userInfo: null,
  loggedIn: false,
  login: null,
  logout: null,
  permissionError: false,
  loginFunction: null,
  setPermissionError: null,
  setLoginFunction: null,
  getUserId: null,
  isAdminUser: null,
});

const oktaAuth = new OktaAuth(config.oidc);

const UserProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [userState, setUserState] = useState<UserInfo | undefined>();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [permissionError, setPermissionError] = useState<boolean>(false);
  const [loginFunction, setLoginFunction] = useState(null);
  const { authState, oktaAuth } = useOktaAuth();

  const getUserId = (): number => {
    return userState.id;
  };

  const isAdminUser = (): boolean => {
    return userState?.role?.id === UserRoleConstants.ADMIN;
  };

  const login = async (userData: UserInfo): Promise<void> => {
    const userInfoData = await UserApi.getUserInfo();
    if (userInfoData) {
      userData = { ...userData, ...userInfoData };
      setUserState(userData);
      setLoggedIn(true);
    }
  };

  const logout = async (): Promise<void> => {
    await oktaAuth.signOut();
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      // when the user becomes authenticated, call onLogin() to populate AuthContext's user info
      login({ accessToken: authState.accessToken });
    }
  }, [authState]);

  return (
    <UserContext.Provider
      value={{
        userInfo: userState,
        loggedIn,
        login,
        logout,
        permissionError,
        setPermissionError,
        loginFunction,
        setLoginFunction,
        getUserId,
        isAdminUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): UserContext => {
  const userContext = useContext<UserContext>(UserContext);
  if (userContext === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return userContext;
};

export { UserContext, UserProvider, oktaAuth, useUser, UserInfo };
